@import '@/shared/scss';

.h1 {
  font-weight: 700;
  font-size: 80px;
  line-height: 110%;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  @include linear-text();

  @include laptop {
    font-size: 72px;
  }

  @include tablet {
    font-size: 65px;
  }

  @include ipad {
    font-size: 50px;
  }

  @include mobile {
    font-size: 36px;
    letter-spacing: -0.01em;
  }
}

.h2 {
  font-weight: 600;
  font-size: 40px;
  line-height: 120%;

  @include mobile {
    font-size: 32px;
    letter-spacing: -0.02em;
  }
}

.h3 {
  font-weight: 600;
  font-size: 32px;
  line-height: 38.4px;
}

.h4 {
  font-weight: 600;
  font-size: 24px;
  line-height: 28.8px;
}

.p {
  font-weight: 300;
  font-size: 24px;
  line-height: 120%;
  color: var(--txt-secondary-wight);

  @include mobile {
    font-size: 20px;
    line-height: 140%;
  }
}

.visuallyHidden {
  position: absolute;
  overflow: hidden;
  margin: -1px;
  border: 0;
  padding: 0;
  width: 1px;
  height: 1px;
  clip: rect(0 0 0 0);
}
