@import '@/shared/scss';

.section {
  padding-top: 42px;

  &:before {
    content: '';
    display: block;
    position: absolute;
    z-index: -3;
    width: 316.5px;
    height: 475px;
    pointer-events: none;
    bottom: -200px;
    left: 0;
    background-image: url('../../../public/images/blur-backgrounds/14.png');
    background-size: cover;
    background-repeat: no-repeat;

    @include tablet {
      bottom: 70px;
    }
  }

  @include mobile {
    padding-top: 18px;
  }
}

.container {
  display: grid;
  gap: 24px;
  align-items: center;
  grid-template-columns: repeat(12, 1fr);

  @include tablet {
    display: flex;
    align-items: stretch;
    flex-direction: column-reverse;
    row-gap: 35px;
  }

  @include mobile {
    row-gap: 35px;
  }
}

.right {
  grid-column: 5 span;
  padding-left: 30px;

  @include tablet {
    padding-left: 0;
  }
}

.title {
  margin-bottom: 32px;

  @include mobile {
    margin-bottom: 24px;
  }
}

.items {
  display: flex;
  gap: 32px;
  flex-direction: column;

  @include mobile {
    gap: 24px;
  }
}

.flex-start {
  align-items: flex-start;
}

.center {
  align-items: center;
}

.item {
  display: flex;
  gap: 8px;

  @include mobile {
    align-items: flex-start;
    flex-direction: column;
    gap: 7px;
  }
}

.label {
  font-size: 20px;
  line-height: 140%;
  font-weight: 300;
  color: var(--txt-secondary-wight);

  @include mobile {
    font-size: 16px;
    line-height: 19.2px;
  }
}

.value {
  font-size: 20px;
  line-height: 140%;
  font-weight: 500;

  @include mobile {
    font-size: 16px;
    line-height: 22.4px;

    ul {
      padding-top: 4px;
    }
  }
}
