@import '@/shared/scss';

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: var(--vh, 100vh);
  background: var(--block-dark);
  z-index: var(--modal-z-index);
  transition:
    opacity var(--duration),
    visibility var(--duration);

  &.isMounted {
    opacity: 1;
    visibility: visible;
  }

  &.isUnmounted {
    opacity: 0;
    visibility: hidden;
  }
}

.close {
  position: absolute;
  top: 24px;
  right: 32px;
  width: 32px;
  height: 32px;
  transition: transform var(--duration);

  @include mobile {
    top: 16px;
    right: 16px;
  }

  @include hover {
    transform: scale(1.15);
  }

  svg {
    display: block;
    width: 100%;
    height: 100%;
  }
}
