@import '@/shared/scss';

.item1 {
  @include responsive-property(padding-left, 208);
  @include responsive-property(padding-right, 131);

  @include tablet {
    @include responsive-property(padding-left, 120, true);
    @include responsive-property(padding-right, 120, true);
  }

  &.isVisible {
    .image {
      transform: translate(-50px, -50px);

      @include mobile {
        transform: translate(-100px, -20px);
      }
    }

    .imageWrapper {
      &:before {
        transform: translate(-50px, 50px);
      }
    }
  }

  @media (max-width: 900px) {
    padding-right: 0;
    padding-left: 30px;
  }

  .imageWrapper {
    &:before {
      content: '';
      display: block;
      position: absolute;
      top: -122px;
      left: -178px;
      width: 744px;
      height: 736px;
      background-image: url('../../../../../public/images/blur-backgrounds/1.png');
      background-size: cover;
      background-repeat: no-repeat;

      @include mobile {
        top: -250px;
        left: -200px;
        scale: 0.6;
      }
    }
  }

  .image {
    top: 41px;
    right: 43px;
    width: 310px;
    height: 358px;

    @include mobile {
      left: calc(50% + 5px);
      top: 22px;
      right: initial;
      transform: translateX(-50%);
      width: 171.8px;
      height: 198.4px;
    }
  }
}

.item2 {
  @include responsive-property(padding-left, 183);
  @include responsive-property(padding-right, 174);

  @media (max-width: 1280px) {
    padding-right: 70px;
  }

  @include tablet {
    @include responsive-property(padding-left, 100, true);
    @include responsive-property(padding-right, 100, true);
  }

  @media (max-width: 900px) {
    padding-left: 0;
    padding-right: 30px;
  }

  &.isVisible {
    .image {
      transform: translate(50px, -50px);

      @include mobile {
        transform: translate(20px, -40px);
      }
    }

    .imageWrapper {
      &:before {
        transform: translate(-50px, 50px);
      }
    }
  }

  &:before {
    content: '';
    display: block;
    position: absolute;
    z-index: -3;
    width: 1200px;
    height: 2402px;
    pointer-events: none;
    top: -250px;
    left: 0;
    background-image: url('../../../../../public/images/blur-backgrounds/2.png');
    background-size: cover;
    background-repeat: no-repeat;
  }

  .imageWrapper {
    &:before {
      content: '';
      display: block;
      position: absolute;
      top: -40px;
      left: -95px;
      width: 574px;
      height: 508px;
      background-image: url('../../../../../public/images/blur-backgrounds/3.png');
      background-size: cover;
      background-repeat: no-repeat;

      @include mobile {
        top: -120px;
        left: -170px;
        scale: 0.6;
      }
    }
  }

  .text {
    transform: translateY(-6px);
  }

  .desc {
    max-width: 320px;
  }

  .image {
    top: 41px;
    left: 30px;
    width: 364px;
    height: 328px;

    @include mobile {
      width: 201.73px;
      height: 181.78px;
    }
  }
}

.item3 {
  transform: translateY(-15px);
  @include responsive-property(padding-left, 208);
  @include responsive-property(padding-right, 115);

  @include tablet {
    padding-left: 70px;
    padding-right: 70px;
  }

  @media (max-width: 900px) {
    padding-left: 30px;
    padding-right: 0;
  }

  &.isVisible {
    .image {
      transform: translate(-35px, -35px);
    }

    .imageWrapper {
      &:before {
        transform: translate(-50px, 50px);
      }
    }
  }

  .imageWrapper {
    @media (max-width: 900px) {
      transform: translateX(40px);
    }

    @include ipad {
      margin-bottom: 30px;
    }

    @include mobile {
      transform: translateX(10px);
    }

    &:before {
      content: '';
      display: block;
      position: absolute;
      top: -122px;
      left: -178px;
      width: 744px;
      height: 736px;
      background-image: url('../../../../../public/images/blur-backgrounds/1.png');
      background-size: cover;
      background-repeat: no-repeat;

      @include mobile {
        top: -240px;
        left: -200px;
        scale: 0.6;
      }
    }
  }

  .image {
    top: 30px;
    max-width: initial;
    right: 15px;
    width: 506px;
    height: 564px;

    @include mobile {
      width: 303.42px;
      height: 338.2px;
    }
  }

  .puddleImage {
    width: 430px;
    height: 480px;

    @include mobile {
      width: 257.85px;
      height: 287.83px;
      margin-left: 14px;
    }
  }

  .text {
    transform: translateY(34px);
  }

  .desc {
    max-width: 298px;
  }
}

.item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: var(--flex-direction);

  @include ipad {
    flex-direction: column-reverse;
    padding: 0;

    .title,
    .desc {
      max-width: 600px;
      margin-left: auto;
      margin-right: auto;
      text-align: center;
    }
  }

  @include mobile {
    row-gap: 20px;

    .desc {
      max-width: 300px;
    }

    &.item2 {
      .desc {
        max-width: 250px;
      }
    }

    &.item3 {
      row-gap: 30px;
    }
  }
}

.imageWrapper {
  position: relative;
  flex-shrink: 0;

  &:before {
    transition: transform 0.9s cubic-bezier(0.42, 0, 0.58, 1);
  }

  @include tablet {
    scale: 0.8;
  }

  @media (max-width: 900px) {
    scale: 0.75;
  }

  @include mobile {
    scale: 1;
  }
}

.image {
  position: absolute;
  transition: transform 0.9s cubic-bezier(0.42, 0, 0.58, 1);
}

.puddleImage {
  width: 394px;
  height: 440px;

  @include mobile {
    width: 218.35px;
    height: 243.85px;
  }
}

.text {
  position: relative;
  z-index: 10;
  display: flex;
  flex-direction: column;

  @include ipad {
    max-width: 600px;
    width: 100%;
  }
}

.title {
  max-width: 352px;
  margin-bottom: 24px;

  @include ipad {
    margin-bottom: 16px;
  }

  &.yellowTitle {
    color: var(--action-ellow);
  }
}

.desc {
  max-width: 340px;
}

.btn {
  margin-top: 32px;

  @include ipad {
    margin: 24px auto 0;
  }
}

.btn {
  width: 280px;

  @include ipad {
    max-width: 450px;
    width: 100%;
  }
}
