@import '@/shared/scss';

.modal {
  padding: 30px 0;
  overflow-x: hidden;
  overflow-y: auto;
  text-align: center;

  .form {
    padding: 0;
    background: transparent;
  }

  .notification {
    position: fixed;
    z-index: 3;
    left: 50%;
    top: auto;
    bottom: 14px;
    transform: translateX(-50%);

    @include mobile {
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }

  &:before {
    content: '';
    display: inline-block;
    vertical-align: middle;
    height: 100%;
  }
}

.container {
  display: inline-flex;
  flex-direction: column;
  vertical-align: middle;
  max-width: calc(356px + calc(32px * 2));
  padding: 0 32px;
  row-gap: 32px;
  text-align: left;
}
