@import '@/shared/scss';

.section {
  @include mobile {
    margin-bottom: 41px;
  }

  &:before {
    content: '';
    position: absolute;
    z-index: -1;
    top: -580px;
    left: 0;
    width: 869px;
    height: 1536.5px;
    background-image: url('../../../public/images/blur-backgrounds/11.png');
    background-repeat: no-repeat;
    background-size: cover;
  }

  &:after {
    content: '';
    position: absolute;
    z-index: -1;
    top: -580px;
    right: 0;
    left: auto;
    width: 853px;
    height: 1536.5px;
    background-image: url('../../../public/images/blur-backgrounds/12.png');
    background-repeat: no-repeat;
    background-size: cover;

    @include tablet {
      display: none;
    }
  }
}

.title {
  margin-bottom: 40px;

  @include mobile {
    text-align: center;
    margin-bottom: 32px;
  }
}

.list {
  counter-reset: list;
  display: grid;
  grid-template-columns: 3fr 3fr 2.3fr 1.7fr 2.1fr 1.3fr;

  @include ipad {
    display: flex;
    flex-direction: column;
  }
}

.item {
  --counter-size: 44px;
  position: relative;
  display: inline-flex;
  row-gap: 16px;
  flex-direction: column;
  counter-increment: list;

  &:not(:first-child) {
    .label {
      max-width: 200px;
    }
  }

  @include ipad {
    gap: 9px;
    align-items: center;
    flex-direction: row;
    padding-bottom: 36px;
  }

  &:before {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    content: counter(list);
    z-index: 1;
    font-size: 24px;
    line-height: 120%;
    font-weight: 500;
    border-radius: 100%;
    width: var(--counter-size);
    height: var(--counter-size);
    border: 2px solid var(--action-ellow);
    color: var(--action-ellow);
    background: var(--block-dark);

    @include mobile {
      line-height: 140%;
    }
  }

  &:not(:last-child) {
    &:after {
      content: '';
      display: block;
      position: absolute;
      top: calc(calc(var(--counter-size) / 2) - 1.5px);
      left: var(--counter-size);
      right: 0;
      height: 2px;
      background-color: var(--border-lght);

      @include ipad {
        right: auto;
        top: var(--counter-size);
        bottom: -20px;
        width: 2px;
        height: auto;
        left: calc(calc(var(--counter-size) / 2) - 1.5px);
      }
    }
  }
}

.label {
  display: block;
  font-size: 24px;
  font-weight: 300;
  line-height: 120%;

  a {
    white-space: nowrap;
    @include linear-text(var(--cta-prinary));

    &:hover {
      @include linear-text(var(--prim-button));
    }
  }

  @include tablet {
    padding-right: 5px;
  }

  @include ipad {
    padding-right: 0;
  }

  @include mobile {
    font-size: 20px;
    line-height: 140%;
  }
}

.coin {
  @include responsive-property(width, 159);
  @include responsive-property(height, 129);
  top: -7px;
  right: -50px;
  transform: translateY(var(--transform));

  @include ipad {
    display: none;
  }
}
