@import '@/shared/scss';

.root {
  position: relative;
  margin-bottom: 120px;

  @include ipad {
    margin-bottom: 100px;
  }

  @include mobile {
    margin-bottom: 80px;
  }
}
