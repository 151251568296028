@import '@/shared/scss';

.title {
  text-align: center;
  margin-bottom: 39px;

  @include mobile {
    text-align: left;
    margin-bottom: 32px;
  }
}

.list {
  display: grid;
  gap: 24px;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);

  @include tablet {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(4, 1fr);
  }

  @include mobile {
    gap: 32px;
  }
}

.item {
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 27px 24px;
  background: var(--block-light);
  border-radius: var(--radius);

  @include mobile {
    padding: 17px 16px;
  }
}

.icon {
  width: 48px;
  height: 48px;
  flex-shrink: 0;
}

.label {
  font-weight: 300;
  font-size: 24px;
  line-height: 120%;
  color: var(--txt-secondary-black);

  @include mobile {
    font-size: 18px;
  }
}

.coin {
  @include responsive-property(width, 130);
  @include responsive-property(height, 103);
  top: -50px;
  right: 18px;
  transform: translateY(var(--transform));

  @include mobile {
    display: none;
  }
}

.diamond {
  @include responsive-property(width, 145);
  @include responsive-property(height, 140);
  bottom: -128px;
  left: calc(50% - 23px);
  transform: translateY(var(--transform)) translateX(-50%);

  @include mobile {
    display: none;
  }
}
