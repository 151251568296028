@import '@/shared/scss';

.section {
  &:before,
  &:after {
    content: '';
    display: block;
    position: absolute;
    z-index: -3;
    background-size: cover;
    background-repeat: no-repeat;
    pointer-events: none;

    @include mobile {
      display: none;
    }
  }

  &:before {
    top: -400px;
    left: 0;
    width: 1200px;
    height: 1692px;
    background-image: url('../../../public/images/blur-backgrounds/6.png');
  }

  &:after {
    bottom: -150px;
    right: 0;
    width: 270px;
    height: 523px;
    background-image: url('../../../public/images/blur-backgrounds/7.png');
  }
}

.container {
  position: relative;
}

.title {
  margin-bottom: 43px;

  @include mobile {
    margin-bottom: 30px;
  }
}

.btns {
  position: absolute;
  top: 0;
  right: var(--container-offset);
  display: flex;
  align-items: center;
  gap: 24px;

  @include tablet {
    position: static;
    display: grid;
    margin-top: 32px;
    grid-template-columns: repeat(2, 1fr);
  }

  @include mobile {
    gap: 16px;
    grid-template-columns: 1fr;
  }
}

.videoWrapper {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
  border-radius: 12px;
  overflow: hidden;

  @include mobile {
    border-radius: 8px;
  }
}

.video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
}
