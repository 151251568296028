@import '@/shared/scss';

.root {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: var(--header-z-index);
}

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 24px;
  padding-bottom: 24px;
}

.right {
  display: flex;
  align-items: center;
  gap: 7px;
}

.menu {
  color: #e9f4ff;
  transition: color var(--duration);

  &:hover {
    color: #fdc453;
  }

  svg {
    width: 40px;
    height: 40px;
  }
}
