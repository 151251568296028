.logo {
  display: block;
  width: 169px;
  height: 34px;
}

.img {
  display: block;
  width: 100%;
  height: 100%;
}
