@import '@/shared/scss';

.grid {
  --column: 40.5%;
  --gap: 24px;
  display: grid;
  gap: var(--gap);
  grid-template-rows: repeat(10, 1fr);
  grid-template-columns: calc(calc(100% - var(--column)) - var(--gap)) var(
      --column
    );
  height: 820px;

  @media (max-width: 1224px) {
    @include responsive-property(height, 900);
  }

  @include tablet {
    --gap: 16px;
  }

  @include mobile {
    --gap: 8px;
    --column: 37.5%;
    @include responsive-property(height, 1080);
  }
}

.item {
  display: block;
  position: relative;
  grid-row: 3 span;
  border-radius: 8px;
  overflow: hidden;

  &:before {
    content: '';
    display: block;
    position: absolute;
    z-index: 2;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0.1);
    width: 500px;
    height: 500px;
    border-radius: 100%;
    background-color: #fff;
    opacity: 0;
    transition:
      transform var(--duration),
      opacity var(--duration);
  }

  &:hover {
    &:before {
      opacity: 0.1;
      transform: translate(-50%, -50%) scale(2);
    }
  }

  @include mobile {
    border-radius: 4px;
  }

  &:nth-child(1),
  &:nth-child(4) {
    grid-row: 5 span;
  }

  &:nth-child(3) {
    grid-row: 4 span;
  }
}

.image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
