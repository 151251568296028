@import '@/shared/scss';

.section {
  background: var(--block-light);
}

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 40px;
  padding-bottom: 38px;

  @include ipad {
    max-width: 350px;
    flex-direction: column-reverse;
  }

  @include mobile {
    align-items: stretch;
    max-width: 100%;
    padding-top: 24px;
    padding-bottom: 22px;
  }
}

.title {
  font-size: 24px;
  line-height: 33.6px;
  font-weight: 300;
  max-width: 350px;
  color: var(--txt-secondary-black);
  margin-bottom: 16px;

  @include ipad {
    text-align: center;
    margin-bottom: 24px;
  }

  @include mobile {
    margin: 0 auto;
    max-width: 320px;
    line-height: 120%;
    margin-bottom: 22px;
  }

  span {
    @include linear-text(var(--cta-prinary));
    font-weight: 500;
    line-height: 28.8px;

    @include mobile {
      display: block;
      font-weight: 300;
      line-height: 120%;
    }
  }
}

.btn {
  z-index: 1;

  @include ipad {
    padding-left: 0;
    padding-right: 0;
    width: 100%;
  }
}

.logo {
  @include responsive-property(width, 567);
  @include responsive-property(height, 123);

  img {
    width: 100%;
    height: 100%;
  }

  @include ipad {
    position: relative;
    display: block;
    width: 100%;
    padding-bottom: 22%;
    margin-bottom: 32px;

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}

.coin {
  @include responsive-property(width, 86);
  @include responsive-property(height, 74);
  left: 142px;
  bottom: -45px;
  transform: rotate(5deg) translateY(var(--transform)) scaleX(-1);

  @include ipad {
    display: none;
  }
}
