@import '@/shared/scss';

.list {
  --item-size: 48px;
  --icon-size: 24px;
  display: flex;
  gap: 12px;
  align-items: center;
}

.link {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--item-size);
  height: var(--item-size);
  color: var(--txt-secondary-black);
  background: var(--block-light);
  border-radius: 100%;
  overflow: hidden;

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: var(--action-ellow);
    opacity: 0;
    transition: opacity var(--duration);
  }

  @include hover {
    &:before {
      opacity: 1;
    }
  }

  svg {
    position: relative;
    z-index: 2;
    display: block;
    width: var(--icon-size);
    height: var(--icon-size);
  }
}
