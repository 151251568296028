@import '@/shared/scss';

.top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 9px;
  margin-bottom: 40px;

  @include mobile {
    padding-top: 10px;
    margin-bottom: 32px;
  }
}

.btns {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-top: -9px;
  padding-right: 5px;

  @include mobile {
    gap: 14px;
  }
}

.prev,
.next {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 47px;
  height: 47px;
  border-radius: 13px;
  background-image: url("data:image/svg+xml,%3Csvg width='48' height='48' viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M33.778 0.666016H14.2246C5.7313 0.666016 0.667969 5.72935 0.667969 14.2227V33.7527C0.667969 42.2693 5.7313 47.3327 14.2246 47.3327H33.7546C42.248 47.3327 47.3113 42.2693 47.3113 33.776V14.2227C47.3346 5.72935 42.2713 0.666016 33.778 0.666016ZM30.5113 25.236L22.2746 33.4727C21.9246 33.8227 21.4813 33.986 21.038 33.986C20.5946 33.986 20.1513 33.8227 19.8013 33.4727C19.1246 32.796 19.1246 31.676 19.8013 30.9993L26.8013 23.9993L19.8013 16.9993C19.1246 16.3227 19.1246 15.2027 19.8013 14.526C20.478 13.8493 21.598 13.8493 22.2746 14.526L30.5113 22.7627C31.2113 23.4393 31.2113 24.5593 30.5113 25.236Z' fill='%23D0DBEC'/%3E%3C/svg%3E%0A");
  background-size: cover;
  background-repeat: no-repeat;
  transition:
    transform var(--duration),
    opacity var(--duration);

  @include mobile {
    width: 42px;
    height: 42px;
    border-radius: 10px;
  }

  &[disabled] {
    cursor: default;
    opacity: 0.5;
  }
}

.prev {
  transform: rotate(-180deg);

  &:hover {
    transform: rotate(-180deg) scale(1.05);
  }
}

.next {
  &:hover {
    transform: scale(1.05);
  }
}

.carousel {
  @include tablet {
    overflow: visible;
  }
}

.slide {
  height: auto;
  width: calc(100% / 3);

  @include tablet {
    width: 40%;
  }

  @include ipad {
    width: 87.1%;
  }
}

.item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 8px;
  padding: 24px;
  height: calc(100% - calc(24px * 2));
  background: var(--block-light);
}

.itemTop {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 16px;
}

.itemAvatar {
  display: block;
  width: 80px;
  height: 80px;
  overflow: hidden;
  border-radius: 8px;
}

.itemText {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.itemName {
  font-size: 20px;
  line-height: 140%;
  font-weight: 500;
  color: var(--txt-secondary-black);
}

.itemReview {
  font-weight: 300;
  font-size: 20px;
  line-height: 140%;
  color: var(--txt-secondary-black);
}

.coin {
  @include responsive-property(width, 229);
  @include responsive-property(height, 180);
  top: 110%;
  right: 20px;
  transform: translateY(var(--transform)) rotate(-10deg) scaleX(-1);

  @include tablet {
    display: none;
  }
}
