$tablet-width: 1024px;
$min-tablet: $tablet-width + 1px;
$ipad-width: 768px;
$min-ipad-width: $ipad-width + 1px;
$mobile-width: 576px;
$min-mobile-width: $mobile-width + 1px;

@mixin tablet {
  @media (max-width: $tablet-width) {
    @content;
  }
}

@mixin min-tablet {
  @media (min-width: $min-tablet) {
    @content;
  }
}

@mixin ipad {
  @media (max-width: $ipad-width) {
    @content;
  }
}

@mixin min-ipad {
  @media (min-width: $min-ipad-width) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: $mobile-width) {
    @content;
  }
}

@mixin min-mobile {
  @media (min-width: $min-mobile-width) {
    @content;
  }
}

@mixin laptop {
  @media (max-width: 1400px) {
    @content;
  }
}
