@import '@/shared/scss';

.section {
  padding-top: 88px;
  margin-bottom: 64px;

  &:before {
    content: '';
    display: block;
    position: absolute;
    z-index: -3;
    width: 326px;
    height: 577.5px;
    pointer-events: none;
    top: 30%;
    right: 0;
    background-image: url('../../../public/images/blur-backgrounds/13.png');
    background-size: cover;
    background-repeat: no-repeat;

    @include ipad {
      display: none;
    }
  }
}

.container {
  display: flex;
  row-gap: 23.5px;
  flex-direction: column;
}

.item {
  display: flex;
  flex-direction: column;

  &:nth-child(3),
  &:nth-child(6) {
    .itemList {
      row-gap: 0;
    }
  }
}

.title {
  margin-bottom: 24px;
}

.title:not(h2) {
  color: var(--txt-secondary-wight);
  margin-bottom: 16px;
}

.descriptions {
  display: flex;
  row-gap: 18px;
  flex-direction: column;

  &:not(:last-child) {
    margin-bottom: 20px;
  }
}

.desc,
.listItem {
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
  color: var(--txt-secondary-wight);

  a {
    @include linear-text(var(--cta-prinary));
    text-decoration: underline;
    text-decoration-color: #4d86ba;

    &:hover {
      @include linear-text(var(--prim-button));
      text-decoration-color: #fdc453;
    }
  }
}

.itemList {
  display: flex;
  row-gap: 20px;
  flex-direction: column;
  padding-left: 10px;
  margin-left: 14px;
  list-style: disc;

  &:not(:last-child) {
    margin-bottom: 17px;
  }
}
