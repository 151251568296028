@import '@/shared/scss';

.container {
  display: flex;
  flex-direction: column;

  @include mobile {
    row-gap: 123px;
  }
}

.coin {
  @include responsive-property(width, 98);
  @include responsive-property(height, 77);
  top: 860px;
  left: calc(50% + 16px);
  transform: translateX(-50%) translateY(var(--transform)) rotate(-15deg);

  @include ipad {
    display: none;
  }
}
