@import '@/shared/scss';

.section {
  &:before,
  &:after {
    content: '';
    display: block;
    position: absolute;
    z-index: -1;
    background-size: cover;
    background-repeat: no-repeat;
    pointer-events: none;

    @include mobile {
      display: none;
    }
  }

  &:before {
    top: -500px;
    left: 0;
    width: 869px;
    height: 1537px;
    background-image: url('../../../public/images/blur-backgrounds/9.png');
  }

  &:after {
    top: -500px;
    right: 0;
    width: 853px;
    height: 1537px;
    background-image: url('../../../public/images/blur-backgrounds/10.png');
  }
}

.title {
  text-align: center;
  margin-bottom: 56px;

  @include mobile {
    margin-bottom: 24px;
  }
}

.list {
  display: grid;
  gap: 24px;
  grid-template-columns: repeat(4, 1fr);

  @include tablet {
    grid-template-columns: repeat(2, 1fr);
  }

  @include mobile {
    row-gap: 64px;
    grid-template-columns: 1fr;
  }
}

.coin {
  z-index: -1;
  @include responsive-property(width, 98);
  @include responsive-property(height, 77);
  top: 112px;
  left: 47.8%;
  transform: rotate(-13deg) translateY(var(--transform));

  @include tablet {
    display: none;
  }
}
