@import '@/shared/scss';
@import 'intl-tel-input/styles';

.iti {
  display: grid;
  width: 100%;

  &__selected-country-primary {
    position: relative;
    padding: 0 16px;
    padding-right: 32px;

    &:after {
      content: '';
      display: block;
      position: absolute;
      width: 1px;
      height: 20px;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      background: #a4a4a4;
    }
  }

  &__country-name {
    color: var(--txt-secondary-black);
  }

  &__arrow {
    width: 20px;
    height: 20px;
    position: absolute;
    border: none;
    top: 50%;
    left: 36px;
    transform: translateY(-50%);
    background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13.9232 8.9245L10.5899 12.2578C10.5124 12.3359 10.4202 12.3979 10.3187 12.4402C10.2171 12.4826 10.1082 12.5043 9.9982 12.5043C9.88819 12.5043 9.77927 12.4826 9.67772 12.4402C9.57617 12.3979 9.484 12.3359 9.40653 12.2578L6.0732 8.9245C5.9955 8.8468 5.93387 8.75456 5.89182 8.65304C5.84977 8.55153 5.82813 8.44272 5.82812 8.33284C5.82812 8.22295 5.84977 8.11415 5.89182 8.01263C5.93387 7.91111 5.9955 7.81887 6.0732 7.74117C6.1509 7.66347 6.24314 7.60184 6.34466 7.55979C6.44618 7.51774 6.55499 7.49609 6.66487 7.49609C6.77475 7.49609 6.88356 7.51774 6.98508 7.55979C7.08659 7.60184 7.17884 7.66347 7.25653 7.74117L9.9982 10.4912L12.7399 7.74117C12.8968 7.58425 13.1096 7.49609 13.3315 7.49609C13.5535 7.49609 13.7663 7.58425 13.9232 7.74117C14.0801 7.89809 14.1683 8.11092 14.1683 8.33284C14.1683 8.55475 14.0801 8.76758 13.9232 8.9245Z' fill='%2393A6BD'/%3E%3C/svg%3E%0A");
    background-size: cover;
  }

  &__tel-input {
    padding-left: 80px !important;
    @include gl-input;
  }
}
