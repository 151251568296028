@import '@/shared/scss';

.item {
  display: flex;
  gap: 16px;
  align-items: center;
  flex-direction: column;
  cursor: pointer;

  &:nth-child(3) {
    .image {
      width: 280px;
      height: 260px;
    }
  }

  @include hover {
    .imageWrapper {
      transform: scale(1.05);
    }

    .puddle {
      color: var(--action-ellow);
    }
  }

  &.isVisible {
    @include mobile {
      .imageWrapper {
        transform: scale(1.05);
      }

      .puddle {
        color: var(--action-ellow);
      }
    }
  }

  @include mobile {
    gap: 12px;
  }
}

.imageWrapper {
  position: relative;
  transition: transform 0.5s cubic-bezier(0.42, 0, 0.58, 1);
}

.image {
  width: 242px;
  margin: 24px 30px 0 16px;
  object-fit: contain;
  height: 263px;

  @include mobile {
    margin: 0 16px;
  }
}

.puddle {
  position: absolute;
  z-index: -1;
  left: 16px;
  bottom: 5px;
  width: 242px;
  height: 189px;
  color: rgba(#8a90a0, 0.13);
  transition: color var(--duration);
}

.label {
  max-width: 230px;
  text-align: center;
  margin: 0 auto;
  font-size: 20px;
  line-height: 28px;
  font-weight: 300;
  text-align: center;
  color: var(--txt-secondary-wight);
}
