@import '@/shared/scss';
.container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  row-gap: 16px;
  column-gap: 8px;
  padding-top: 24px;
  padding-bottom: 24px;
}

.link {
  font-weight: 300;
  font-size: 20px;
  line-height: 28px;
  color: var(--txt-secondary-wight);
  margin-left: auto;
  margin-right: 24px;

  @include mobile {
    font-size: 16px;
    line-height: 19.2px;
    width: 100%;
    text-align: center;
    order: 1;
    margin: 0;
  }

  @include hover {
    @include linear-text(var(--cta-prinary));
  }
}

.copy {
  line-height: 120%;
  color: var(--txt-secondary-wight);
}
