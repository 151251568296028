@import '@/shared/scss';

.form {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 30px 24px;
  row-gap: 30px;
  background: var(--block-dark);
  grid-column: 7 span;
  border-radius: 8px;

  @include mobile {
    padding: 23px 24px;
  }
}

.fields {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}

.input {
  @include gl-input;
}

.checkbox {
  cursor: pointer;

  .checkboxWrapper {
    display: flex;
    align-items: center;
    gap: 8px;
    user-select: none;
    position: relative;

    span {
      position: relative;
      font-size: 12px;
      line-height: 120%;

      @include mobile {
        line-height: 16.8px;
      }

      a {
        @include linear-text(var(--cta-prinary));

        &:hover {
          @include linear-text(var(--prim-button));
        }
      }
    }

    &:before {
      content: '';
      display: block;
      width: 20px;
      height: 20px;
      flex-shrink: 0;
      border-radius: 4px;
      border: 1px solid var(--border-lght);
      transition: border-color var(--duration);
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      width: 20px;
      height: 20px;
      flex-shrink: 0;
      border-radius: 4px;
      background-size: 12px;
      opacity: 0;
      background-repeat: no-repeat;
      background-position: center;
      background-image: url("data:image/svg+xml,%3Csvg width='12' height='10' viewBox='0 0 12 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.625 5L4.4375 8.125L10.375 1.875' stroke='%23FCFDF2' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
      transition: opacity var(--duration);
    }
  }

  input {
    position: absolute;
    opacity: 0;
    &:checked ~ .checkboxWrapper {
      &:before {
        border-color: transparent;
        background: var(--cta-prinary);
      }

      &:after {
        opacity: 1;
      }
    }
  }
}

.textarea {
  min-height: 92px;
  resize: vertical;
}

.btn {
  z-index: 1;

  @include mobile {
    min-width: 100%;
  }
}

.notification {
  position: absolute;
  display: flex;
  align-items: center;
  gap: 12px;
  left: 100%;
  top: 24px;
  border-radius: 8px;
  padding: 16px 12px;
  background-color: #173d2d;
  opacity: 0;
  visibility: hidden;
  transition:
    opacity var(--duration),
    visibility var(--duration);

  @include mobile {
    z-index: 3;
    top: -56px;
    left: 50%;
    transform: translateX(-50%);
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  &.showNotification {
    opacity: 1;
    visibility: visible;
  }
}

.notificationIcon {
  width: 24px;
  height: 24px;
  flex-shrink: 0;
}

.notificationText {
  font-weight: 300;
  font-size: 16px;
  line-height: 19.2px;
  white-space: nowrap;
}

.notificationClose {
  display: block;
  width: 24px;
  height: 24px;

  svg {
    display: block;
    width: 100%;
    height: 100%;
  }
}
