@import '@/shared/scss';

.section {
  @include mobile {
    margin-bottom: 64px;
  }
}

.title {
  margin-bottom: 39px;

  @include mobile {
    margin-bottom: 30px;
  }
}

.list {
  display: grid;
  gap: 40px;
  column-gap: 36px;
  grid-template-columns: repeat(3, 1fr);

  @include tablet {
    grid-template-columns: repeat(2, 1fr);
  }

  @include ipad {
    gap: 32px;
    grid-template-columns: 1fr;
  }

  @include mobile {
    margin-bottom: 40px;
  }
}

.item {
  display: flex;
  align-items: center;
  gap: 16px;
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 80px;
  height: 80px;
  border-radius: 8px;
  background: var(--cta-prinary);

  @include mobile {
    width: 64px;
    height: 64px;

    svg {
      width: 32px;
      height: 32px;
    }
  }
}

.label {
  font-size: 18px;
  line-height: 120%;
  color: var(--txt-secondary-wight);
  font-weight: 300;

  @include mobile {
    font-size: 16px;
  }
}
