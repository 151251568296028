@import '@/shared/scss';

.title {
  text-align: center;
  margin-bottom: 39px;

  @include mobile {
    margin-bottom: 32px;
  }
}

.list {
  display: grid;
  gap: 40px;
  align-items: flex-start;
  grid-template-columns: repeat(3, 1fr);

  @include ipad {
    align-items: center;
    grid-template-columns: repeat(2, 1fr);
  }

  @include mobile {
    display: flex;
    flex-direction: column;
    row-gap: 31px;
  }
}

.item {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @include ipad {
    &:last-child {
      grid-column: 2 span;
    }
  }
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--cta-prinary);
  margin-bottom: 24px;
  width: 80px;
  height: 80px;
  border-radius: 8px;

  svg {
    display: block;
    width: 48px;
    height: 48px;
  }
}

.label {
  font-weight: 300;
  text-align: center;
  font-size: 18px;
  line-height: 120%;
  color: var(--txt-secondary-wight);

  @include ipad {
    max-width: 450px;
    margin: 0 auto;
  }

  @include mobile {
    font-size: 16px;
  }

  span {
    display: block;
  }
}

.diamond {
  @include responsive-property(width, 140);
  @include responsive-property(height, 239.5);
  top: -30px;
  left: 0;
  transform: translateY(var(--transform));

  @include mobile {
    display: none;
  }
}
